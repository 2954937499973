import React from "react";
import Layout from "../components/Layout";
import MapBuilder from "../components/MapBuilder";

const Map = (props: any) => {

  return (
    <Layout fullWidth>
      <MapBuilder src={props.location.state}/>
    </Layout>
  )
};

export default Map;
