import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Button, Box } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoadingIcon from "./LoadingIcon";

const MapBuilder = ({ src }: any) => {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  let mapImage: string | undefined

  if (src === undefined) {
    const data = useStaticQuery(graphql`
    {
      mapBuilder: allMarkdownRemark(
        filter: {frontmatter: {templateKey: {eq: "map-page"}}}
      ) {
        edges {
          node {
            frontmatter {
              title
              mapType
              mapList {
                mapImage
              }
            }
          }
        }
      }
    }
  `)
    mapImage = data.mapBuilder.edges[0].node.frontmatter.mapList[0].mapImage;
  }
  else {
    const { mapList } = src
    mapImage = mapList[0].mapImage
  }

  const imageLoaded = () => {
    setLoading(false);
  }

  return (
    <>
      {loading && <LoadingIcon />}
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
          <React.Fragment>
            {!loading &&
              <Box className="tools" sx={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                right: "30px",
                bottom: "40px",
                zIndex: "10",
                backgroundColor: theme.palette.primary.main,
                borderRadius: "10px",
              }}>
                <Button variant="contained" onClick={() => zoomIn()} sx={{ minWidth: "auto", width: "35px", height: "30px", padding: "6px 11px" }}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="6.3" width="5.4" height="18" fill="#D9D9D9" />
                    <rect y="11.7" width="5.4" height="18" transform="rotate(-90 0 11.7)" fill="#D9D9D9" />
                  </svg>
                </Button>
                <Box sx={{ height: "1px", backgroundColor: theme.palette.primary.light, margin: "auto 8px" }} />
                <Button variant="contained" onClick={() => zoomOut()} sx={{ minWidth: "auto", width: "35px", height: "30px", padding: "6px 11px" }}>
                  <svg width="18" height="6" viewBox="0 0 18 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="6" width="6" height="18" transform="rotate(-90 0 6)" fill="#D9D9D9" />
                  </svg>
                </Button>
                <Box sx={{ height: "1px", backgroundColor: theme.palette.primary.light, margin: "auto 8px" }} />
                <Button variant="contained" onClick={() => resetTransform()} sx={{ minWidth: "auto", width: "35px", height: "30px", padding: "6px 11px" }}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.80761 5.05026L5.05025 0.807617L9.99999 5.75735L14.9497 0.807617L19.1924 5.05023L14.2426 10L19.1924 14.9498L14.9497 19.1924L9.99999 14.2426L5.05025 19.1924L0.807617 14.9497L5.75735 10L0.80761 5.05026Z" fill="#D9D9D9" />
                  </svg>
                </Button>
              </Box>
            }
            <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }} contentStyle={{ width: '100%', height: '100%' }}>
              <img src={mapImage} style={{ display: loading ? "none" : "block" }} onLoad={imageLoaded} />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </>
  )
}

export default MapBuilder