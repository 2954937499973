import React from 'react'
import { Box } from "@mui/material";
import './../styles/LoadingIcon.css'

const LoadingIcon = () => {
    return (
        <Box sx={{
            paddingTop: "70px"
          }}>
            <svg
                viewBox="0 0 24 24"
                xmlns="<http://www.w3.org/2000/svg>"
                aria-labelledby="svg-icon-loading"
                className="main"
            >
                <title id="svg-icon-loading">Loading</title>
                <circle
                    cx="12" cy="12" r="8"
                    strokeWidth="3" stroke="#FDD577"
                    fill="none"
                />

            </svg></Box>
    )
}

export default LoadingIcon